import React, { useEffect, useState } from 'react'
import { Button, CardBox, LeaguesTable, MainModal } from '../../components'
import { Leagues } from '../../types'
import { useMutation, useQuery } from '@tanstack/react-query'
import { fetchingLeagues, finishingWeeklyTournament } from '../../services'
import { Loader, ShieldAlert } from 'lucide-react'
import toast from 'react-hot-toast'


export const LeaguesPage = () => {
  const [isLoadingLeagues, setIsLoadingLeagues] = useState<boolean>(true)
  const [leaguesList, setLeaguesList] = useState<Leagues[]>([])
  const [openFinishWeeklyTournamentModal, setOpenFinishWeeklyTournamentModal] = useState(false)

  const {
    data: leagues,
    isSuccess,
    refetch,
  } = useQuery({
    queryFn: () => fetchingLeagues(),
    queryKey: ['leagues'],
  })

  const { mutate: finishWeeklyTournamentMutation, isPending: isFinishingPending } = useMutation({
    mutationFn: () => finishingWeeklyTournament(),
    onSuccess: () => {
      setOpenFinishWeeklyTournamentModal(false)

      refetch().then()
      toast.success('Successfully finished weekly tournament')
    },
    onError: () => {
      toast.error('Something went wrong')
    },
  })

  const finishWeeklyTournamentHandler = () => {
    finishWeeklyTournamentMutation()
  }

  useEffect(() => {
    setLeaguesList(leagues || [])
    setIsLoadingLeagues(!isSuccess)
  }, [leagues, isSuccess])

  return (
    <>
      <CardBox className="flex items-center relative justify-between pb-[140px] sm:pb-[80px] md:pb-[20px]">
        <div className="flex items-center">
          <h1 className="text-[25px] font-semibold">мLeagues</h1>
        </div>
        <div className="flex items-center gap-[5px]">
          <Button className="bg-red-700 text-white font-semibold text-md flex items-center gap-[5px]
              py-[7px] px-[11px] rounded-[8px] hover:bg-red-800 active:bg-red-700"
                  disabled={isLoadingLeagues || isFinishingPending}
                  onClick={() => setOpenFinishWeeklyTournamentModal(true)}
          >
            <ShieldAlert strokeWidth={2.2} size={18}/>
            <span>Finish Weekly Tournament</span>
          </Button>
        </div>
      </CardBox>
      <CardBox className="mt-[8px] relative z-[1] h-[calc(100dvh-164.4px)] lg:h-[calc(100vh-116px)]">
        {!isLoadingLeagues && leaguesList.length > 0 && <LeaguesTable leagues={leaguesList}/>}
        {!isLoadingLeagues && leaguesList.length === 0 && (
          <p className="text-center text-gray-300 mt-[100px]">No leagues yet</p>
        )}
        {isLoadingLeagues && leaguesList.length === 0 && (
          <div className="h-1/2 flex items-center justify-center text-gray-400">
            <Loader size={20} className="animate-spin"/>
          </div>
        )}
      </CardBox>

      <MainModal
        title="Finishing weekly tournament"
        description="Are you sure? This action cannot be undone."
        isOpen={openFinishWeeklyTournamentModal}
        onClose={() => setOpenFinishWeeklyTournamentModal(false)}
        className="w-[400px] relative mt-[-50px] deleting-modal"
      >
        <div className="absolute py-[10px] px-[20px] left-0 right-0 w-full bg-gray-100 min-h-[50px] rounded-b-[15px]">
          <div className="flex justify-end items-center gap-[8px]">
            <Button
              onClick={() => setOpenFinishWeeklyTournamentModal(false)}
              className="bg-white py-[8px] text-gray-700 hover:bg-white"
            >
              Cancel
            </Button>
            <Button
              onClick={finishWeeklyTournamentHandler}
              disabled={isFinishingPending}
              className="bg-red-400 py-[8px] hover:bg-red-300"
            >
              {isFinishingPending ? <Loader size={20} className="animate-spin"/> : <span>Finish</span>}
            </Button>
          </div>
        </div>
      </MainModal>
    </>
  )
}
